





























import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { IReactionDisposer, reaction } from "mobx";
import { Observer } from "mobx-vue";
import { NftModel } from "@/models/nft-model";

@Observer
@Component
export default class AppAvatar extends Vue {
  @Prop() character!: NftModel;
  @Prop({ default: "Unknown" }) owner!: string;
}
